import React from 'react'
import { createRoot } from 'react-dom/client';

import PCT_Links from './links'
import './pct.css'
/**
 * 
 * Application Entry Point 
 */
 function App(props){
    const linksWeb = []
    const linksReports = []
    function handleClick(sender){window.open(sender.target.attributes.link.value,'_blank')}
    PCT_Links.Webs.forEach((item,idx)=>{ linksWeb.push(<div className='link' link={item.link} key={idx} onClick={handleClick}>{item.name}</div>)})
    PCT_Links.Reportes.forEach((item,idx)=>{ linksReports.push(<div className='link' link={item.link} key={idx} onClick={handleClick}>{item.name}</div>)})
    return(
        <div>
            <React.Suspense fallback={<p>Cargando... espere un momento</p>}>
                <div><h1>Plataforma Control de Transporte Explor-K</h1></div>
                <div><h2>Webs</h2></div>
                <div className="flexrow">
                    {linksWeb}
                </div>
                <div><h2>Reportes</h2></div>
                <div className="flexrow">
                    {linksReports}
                </div>
            </React.Suspense>
        </div>
    )
}

//React redering
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App/>);

