const PCT_Links = {
    Webs:[
        {name:"Dashboard.explor-k.com",link:"https://Dashboard.explor-k.cl"},
        {name:"Emergencias.explor-k.com",link:"https://Emergencias.explor-k.cl"},
        {name:"Checkqr.explor-k.com",link:"https://Checkqr.explor-k.cl"},
        {name:"Cinco2.explor-k.com",link:"https://Cinco2.explor-k.cl"},
        {name:"Quemefalta.explor-k.com",link:"https://Quemefalta.explor-k.cl"},
        {name:"Solicitud.explor-k.com",link:"https://Solicitud.explor-k.cl"},
        {name:"Vial2.explor-k.com",link:"https://Vial2.explor-k.cl"},
        {name:"Descarga COPA",link:"https://play.google.com/store/apps/details?id=cl.explork.CPAA"}
    ],
    Reportes:[
        {name:"Prácticos",link:"https://app.powerbi.com/view?r=eyJrIjoiZDgwNzI0ZjItN2M3Ny00NDM1LTgwOWQtMGVkOTkzYTA2ZjFiIiwidCI6ImUyNTk2MDZkLTdjMjAtNDJhMC05MTliLTVkYmU2NDI5ZDE3NiJ9&pageName=ReportSection"},
        {name:"Licencias",link:"https://app.powerbi.com/view?r=eyJrIjoiNGQyYjVkZTgtODIzYi00MjQ0LWEwMWEtZGY3OGNlYjEzZDY5IiwidCI6ImUyNTk2MDZkLTdjMjAtNDJhMC05MTliLTVkYmU2NDI5ZDE3NiJ9&pageName=ReportSection"},
        {name:"Control Ruta SQM",link:"https://app.powerbi.com/view?r=eyJrIjoiMWM3ODBjMGUtMzJmZS00YzliLThiYjgtNzc0ODc1MDVmZGMyIiwidCI6ImUyNTk2MDZkLTdjMjAtNDJhMC05MTliLTVkYmU2NDI5ZDE3NiJ9&pageName=ReportSectionf4f2eef879ab3a097436"}
    ]
}

export default PCT_Links